import { useEffect, useState } from "react"
import { ContentCrossSell } from "~/interfaces/entities/Content"
import { usePrinticularApi } from "./usePrinticularApi"

export const useContentCrossSell = (id: string) => {
  const api = usePrinticularApi()
  const [crossSell, setCrossSell] = useState<ContentCrossSell[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchContent = async () => {
      setIsLoading(true)
      const content = await api.getContent({ id })
      setCrossSell(content?.content?.crossSell || [])
      setIsLoading(false)
    }
    fetchContent()
  }, [id])

  return { crossSell, isLoading }
}
