import GoogleMapReact from "google-map-react"
import React from "react"
import { useSettings } from "~/hooks/useSettings"
import { StoreEntity } from "~/interfaces/entities/Store"
import { usePrinticularMapTemporaryCredentials } from "../store-search/aws/hooks/usePrinticularMapTemporaryCredentials"
import MapAWS from "../store-search/aws/MapAWS"
import StorePins from "../store-search/aws/StorePins"
import StoreMarker from "../store-search/StoreMarker"

interface Props {
  store: StoreEntity
}

const OrderCompleteMap: React.FC<Props> = ({ store }) => {
  const { settings } = useSettings()
  const { credentials } = usePrinticularMapTemporaryCredentials()

  switch (settings.mapProvider) {
    case "googleMaps":
      return (
        <GoogleMapReact
          center={{
            lat: +store.latitude,
            lng: +store.longitude,
          }}
          defaultZoom={17}
        >
          <StoreMarker
            key={store.id}
            lat={+store.latitude}
            lng={+store.longitude}
            label={store.name}
            selected={true}
          />
        </GoogleMapReact>
      )
    case "amazonLocation":
    default:
      if (credentials) {
        return (
          <MapAWS
            credentials={credentials}
            latitude={+store.latitude}
            longitude={+store.longitude}
          >
            <StorePins availableStores={[store]} selectedStoreId={store.id} />
          </MapAWS>
        )
      }
      return null
  }
}

export default OrderCompleteMap
