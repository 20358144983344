import { EntityId } from "@jackfruit/common"
import React from "react"
import { Marker } from "react-map-gl"
import { StoreEntity } from "~/interfaces/entities/Store"
import StoreMarker from "../StoreMarker"
import * as style from "./StorePins.module.scss"

interface Props {
  availableStores: StoreEntity[]
  selectedStoreId: EntityId
  onClick?: (id: EntityId) => void
}

const StorePins: React.FC<Props> = ({
  availableStores,
  selectedStoreId,
  onClick,
}) => {
  return (
    <>
      {availableStores.map(store => {
        return (
          <Marker
            key={store.id}
            longitude={+store.longitude}
            latitude={+store.latitude}
            className={
              store.id === selectedStoreId ? style.mapMarkerSelected : ""
            }
          >
            <StoreMarker
              onClick={() => onClick?.(store.id)}
              lat={+store.latitude}
              lng={+store.longitude}
              label={store.name}
              selected={store.id === selectedStoreId}
            />
          </Marker>
        )
      })}
    </>
  )
}

export default React.memo(StorePins)
